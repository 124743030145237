import styled, { css } from 'styled-components';

export const StyledSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
  }
`;

const ColumnSharedStyles = css`
  width: 100%;
  min-height: 50vw;
  height: 100vw;

  ${({ theme }) => theme.mq.lg} {
    width: 50%;
    height: 50vw;
    max-height: 100rem;
  }
`;

export const StyledTextColumn = styled.div`
  ${ColumnSharedStyles}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3rem;
  padding: 5rem 3rem;
  height: fit-content;

  ${({ theme }) => theme.mq.xl} {
    order: ${({ invert }) => (invert ? '2' : '1')};
    row-gap: 6rem;
  }
`;

export const StyledImageWrapper = styled.div`
  ${ColumnSharedStyles}
  background-image: url(${({ $url }) => $url});
  background-position: left top right bottom;
  background-size: cover;
  background-repeat: no-repeat;

  ${({ theme }) => theme.mq.xl} {
    order: ${({ invert }) => (invert ? '1' : '2')};
  }
`;

export const StyledTitle = styled.div`
  h2 {
    font-size: 3.6rem;
    line-height: 4.2rem;
    font-weight: bolder;
    text-align: center;
    text-transform: uppercase;
    ${({ theme }) => theme.mq.md} {
      font-size: 5.6rem;
      line-height: 6.2rem;
    }
  }
`;

export const StyledText = styled.div`
  max-width: 50rem;
  width: 100%;

  p {
    text-align: center;
    font-size: 1.6rem;
    line-height: 2.2rem;

    ${({ theme }) => theme.mq.md} {
      font-size: 2.5rem;
      line-height: 3.2rem;
    }
  }
`;
