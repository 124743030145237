import React from 'react';
import parse from 'html-react-parser';

import {
  StyledBackground,
  StyledContainer,
  StyledWrapper,
  StyledContent,
  StyledTitle,
  StyledParagraph,
  StyledSubtitle,
  StyledColumn,
  StyledImage,
} from './WhyBaijaStyles';

const WhyBaija = ({ data }) => (
  <StyledBackground
    $url={data.background.localFile.childImageSharp.original.src}
  >
    <StyledImage
      Tag="div"
      fluid={data.background.localFile.childImageSharp.fluid}
      alt={data.title}
    />
    <StyledContainer>
      <StyledWrapper>
        <StyledTitle>{parse(data.title)}</StyledTitle>
        <StyledContent>
          <StyledColumn>
            <StyledSubtitle>{parse(data.subtitle1)}</StyledSubtitle>
            <StyledParagraph>{parse(data.text1)}</StyledParagraph>
          </StyledColumn>
          <StyledColumn>
            <StyledSubtitle>{parse(data.subtitle2)}</StyledSubtitle>
            <StyledParagraph>{parse(data.text2)}</StyledParagraph>
          </StyledColumn>
        </StyledContent>
      </StyledWrapper>
    </StyledContainer>
  </StyledBackground>
);

export default WhyBaija;
