import styled from 'styled-components';
import { breakpoints } from '../../theme/theme';

export const StyledSectionWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${`${breakpoints.xl}px`}) {
    flex-direction: column;
  }
`;

export const StyledTitleWrapper = styled.div`
  background-color: ${({ $backgroundColor }) => `#${$backgroundColor}`};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;
  max-width: 50%;
  width: 100%;

  @media (max-width: ${`${breakpoints.xl}px`}) {
    max-width: 100%;
    min-height: 25vw;
  }
`;

export const StyledTitle = styled.h2`
  font-size: 4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  @media (max-width: ${`${breakpoints.xl}px`}) {
    font-size: 3rem;
  }
  @media (max-width: ${`${breakpoints.sm}px`}) {
    font-size: 2.5rem;
  }
`;

export const StyledImagesWrapper = styled.div`
  max-width: 50%;
  width: 100%;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${`${breakpoints.xl}px`}) {
    max-width: 100%;
  }
`;

export const StyledImageWrapper = styled.div`
  max-width: 50%;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: ${`${breakpoints.xl}px`}) {
    max-width: 50%;
    min-width: 50%;
    max-height: 50vw;
  }

  ${({ theme }) => theme.mq.xxxl} {
    max-width: 50%;
    width: 100%;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
