import styled from 'styled-components';

export const StyledSectionWrapper = styled('div')`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  background: ${({ backgroundColor }) => backgroundColor ?? '#FFFFFF'};
  padding-bottom: 4rem;
  ${({ theme }) => theme.mq.md} {
    padding-bottom: 5rem;
    margin: 0;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding-bottom: 6rem;
  }
`;

export const StyledHeadingWrapper = styled.div`
  width: 100%;
  height: 15rem;
  display: grid;
  place-items: center;
  background-color: ${({ $backgroundColor }) => `#${$backgroundColor}`};
`;

export const StyledHeading = styled('h1')`
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3.2rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  ${({ theme }) => theme.mq.sm} {
    font-size: 3.5rem;
    line-height: 4.2rem;
  }
`;

export const StyledSwiper = styled('div')`
  width: 100vw;
  position: relative;
  z-index: 9;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    z-index: 9;
    ${({ theme }) => theme.mq.lg} {
      width: 80px;
    }
    ${({ theme }) => theme.mq.xxxl} {
      width: 120px;
    }
  }
  .slider-arrow {
    display: none;
    ${({ theme }) => theme.mq.md} {
      box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      height: 45px;
      width: 45px;
      background-color: ${({ theme }) => theme.colors.white};
      z-index: 10;
      color: ${({ theme }) => theme.colors.black};
      &:after {
        font-size: 2rem;
      }
      ${({ theme }) => theme.mq.lg} {
        height: 56px;
        width: 56px;
      }
    }
  }
  .swiper-button-prev {
    left: 60px;
    ${({ theme }) => theme.mq.lg} {
      left: 80px;
    }
    ${({ theme }) => theme.mq.xxxl} {
      left: 120px;
    }
  }
  .swiper-button-next {
    right: 60px;
    ${({ theme }) => theme.mq.lg} {
      right: 80px;
    }
    ${({ theme }) => theme.mq.xxxl} {
      right: 120px;
    }
  }
  .swiper-pagination {
    ${({ theme }) => theme.mq.md} {
      display: none;
    }
  }
  .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    transform: scale(1) !important;
    transition: 0.3s;
    border: 1px solid ${({ theme }) => theme.colors.black};
    &-active {
      background-color: hsla(66, 99%, 66%, 1) !important;
      border-color: hsla(66, 99%, 66%, 1) !important;
    }
  }
  .swiper-slide {
    overflow: hidden;
    height: 31rem;
    width: 23rem;
    transition: transform 0.3s;
    background-color: transparent;
    transform: scale(0.8) !important;
    &.swiper-slide-active {
      transform: scale(1) !important;
    }
    ${({ theme }) => theme.mq.md} {
      height: 42vw;
      width: 32vw;
    }
    ${({ theme }) => theme.mq.lg} {
      min-height: 34vw;
      min-width: 30vw;
    }
    ${({ theme }) => theme.mq.xxxl} {
      height: 25vw;
      width: 23vw;
    }
  }
`;

export const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export const StyledSlideWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const StyledImgWrapper = styled.div`
  height: 23rem;
  width: 23rem;
  text-align: center;

  ${({ theme }) => theme.mq.md} {
    height: 32vw;
    width: 32vw;
  }
  ${({ theme }) => theme.mq.lg} {
    min-height: 30vw;
    min-width: 30vw;
  }
  ${({ theme }) => theme.mq.xxxl} {
    height: 23vw;
    width: 23vw;
  }
`;

export const StyledProductName = styled.p`
  font-weight: 600;
  text-align: center;
  margin-top: 2rem;
`;

export const StyledProductText = styled.p`
  text-align: center;
`;
