import React, { useEffect, useRef } from 'react';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { isBrowser } from 'react-device-detect';
import { sliderAnimation } from 'theme/Animations';
import withLocale from 'hoc/withLocale';

import {
  StyledSectionWrapper,
  StyledHeading,
  StyledSwiper,
  StyledImg,
  StyledImgWrapper,
  StyledProductName,
  StyledProductText,
  StyledHeadingWrapper,
  StyledSlideWrapper,
} from './ProductSliderStyles';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const sliderParams = {
  slidesPerView: 'auto',
  slidesPerGroup: 1,
  centeredSlides: true,
  spaceBetween: 16,
  autoplay: false,
  loop: true,
  pagination: { clickable: true },
  navigation: {
    nextEl: '.swiper-button-next.slider-arrow',
    prevEl: '.swiper-button-prev.slider-arrow',
  },
};

const ProductSlider = ({ data, currentLocale }) => {
  let container = useRef(null);
  let heading = useRef(null);
  let slider = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      sliderAnimation(container, heading, slider);
    }
  }, []);

  return (
    <StyledSectionWrapper
      ref={el => {
        container = el;
      }}
    >
      <StyledHeadingWrapper $backgroundColor={data.backgroundColor}>
        <StyledHeading
          ref={el => {
            heading = el;
          }}
        >
          {data.title}
        </StyledHeading>
      </StyledHeadingWrapper>

      <StyledSwiper
        ref={el => {
          slider = el;
        }}
      >
        <Swiper {...sliderParams}>
          {data?.komponentRelacyjny &&
            data.komponentRelacyjny.map((product, idx) => {
              const alt = product.productImage.alternativeText || '';
              return (
                <SwiperSlide>
                  <StyledSlideWrapper key={idx} alt={alt}>
                    <StyledImgWrapper>
                      <StyledImg
                        onClick={() => {
                          if (product.productImage.caption) {
                            const links = product.productImage.caption.split('|');
                            window.open(
                              currentLocale === 'en' && links[1] ? links[1] : links[0],
                              '_blank'
                            );
                          }
                        }}
                        src={
                          product.productImage.localFile.childImageSharp
                            .original.src
                        }
                        alt={alt}
                      />
                    </StyledImgWrapper>
                    <StyledProductName>{product.name}</StyledProductName>
                    <StyledProductText>{product.description}</StyledProductText>
                    <StyledProductText>{product.size}</StyledProductText>
                  </StyledSlideWrapper>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </StyledSwiper>
    </StyledSectionWrapper>
  );
};

export default withLocale(ProductSlider);
