import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';

export const StyledBackground = styled.div`
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.lightOrange};
  min-height: fit-content;
  background-image: none;

  ${({ theme }) => theme.mq.sm} {
    min-height: 100vh;
    background-image: url(${({ $url }) => $url});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
`;

export const StyledContainer = styled('div')`
  width: 100%;
  height: 100%;
  min-height: fit-content;
  padding: 2rem 1.5rem 6rem;

  ${({ theme }) => theme.mq.md} {
    min-height: 100vh;
    padding: 2rem 5rem 8rem;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 12rem 5rem 12rem;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 16rem 5rem 16rem;
  }
`;

export const StyledWrapper = styled('div')`
  max-width: 140rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
`;

export const StyledTitle = styled('h2')`
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  font-size: 3.2rem;
  margin: 0 0 3rem;

  ${({ theme }) => theme.mq.smm} {
    font-size: 4.2rem;
    margin: 0 0 6rem;
  }

  ${({ theme }) => theme.mq.lg} {
    font-size: 6rem;
    margin: 0 0 10rem;
  }
`;

export const StyledContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 4rem;

  ${({ theme }) => theme.mq.sm} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 6rem;
    margin: 0 0 10rem;
    column-gap: 4rem;
  }

  ${({ theme }) => theme.mq.lg} {
    font-size: 6rem;
    margin: 0 0 10rem;
  }
`;

export const StyledColumn = styled('div')`
  max-width: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  align-items: center;
`;

export const StyledSubtitle = styled('h6')`
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledParagraph = styled('div')`
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
`;

export const StyledBtn = styled(Link)`
  width: auto;
  padding: 15px;
  text-align: center;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
`;

export const StyledImage = styled(Img)`
  cursor: pointer;
  display: block;
  width: 100vw;
  height: 50vw;

  ${({ theme }) => theme.mq.smm} {
    display: none;
  }
`;
