import styled from 'styled-components';
import { Link } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';

export const StyledBackground = styled.div`
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.lightBlue};
  min-height: fit-content;
  background-image: none;

  ${({ theme }) => theme.mq.md} {
    min-height: 100vh;
    background-image: url(${({ $url }) => $url});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
`;

export const StyledContainer = styled('div')`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 2rem 1.5rem 5rem;

  ${({ theme }) => theme.mq.md} {
    padding: 2rem 2.5rem 8rem;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 12rem 2.5rem 15rem;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 14rem 2.5rem 21rem;
  }
`;

export const StyledWrapper = styled('div')`
  max-width: 160rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;

  ${({ theme }) => theme.mq.md} {
    row-gap: 9rem;
  }

  ${({ theme }) => theme.mq.lg} {
    row-gap: 15rem;
  }
`;

export const StyledTitle = styled('h2')`
  max-width: 140rem;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  font-size: 2.4rem;
  margin: 0;

  ${({ theme }) => theme.mq.md} {
    font-size: 5.2rem;
  }
`;

export const StyledContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;

  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4rem;
  }
`;

export const StyledColumn = styled('div')`
  max-width: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  align-items: center;
`;

export const StyledLeftColumn = styled('div')`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  ${({ theme }) => theme.mq.md} {
    row-gap: 12rem;
  }
`;

export const StyledSubtitle = styled('h6')`
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledParagraph = styled('div')`
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
`;

export const StyledBtn = styled(Link)`
  width: auto;
  padding: 15px;
  text-align: center;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.greyLight};
  }
`;

export const StyledImage = styled(Img)`
  cursor: pointer;
  display: block;
  width: 100vw;
  height: 50vw;

  ${({ theme }) => theme.mq.md} {
    display: none;
  }
`;

export const StyledImageWrapper = styled.div`
  cursor: pointer;
  margin: auto auto 2rem;
  padding-top: 2rem;
`;
