import React, { useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { isBrowser } from 'react-device-detect';
import { moisturizeAnimation } from 'theme/Animations';
import {
  StyledSectionWrapper,
  StyledText,
  StyledTextColumn,
  StyledTitle,
  StyledImageWrapper,
} from './CreamsScreenInHalfStyled';

const CreamsScreenInHalf = ({ data }) => {
  let container = useRef(null);
  let content = useRef(null);
  let image = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      moisturizeAnimation(container, content, image);
    }
  }, []);

  return (
    <StyledSectionWrapper
      ref={el => {
        container = el;
      }}
    >
      <StyledTextColumn
        invert={data.invert}
        ref={el => {
          content = el;
        }}
      >
        <StyledTitle>{parse(data.title)}</StyledTitle>
        <StyledText>{parse(data.text)}</StyledText>
      </StyledTextColumn>
      <StyledImageWrapper
        ref={el => {
          image = el;
        }}
        invert={data.invert}
        $url={data.image.localFile.childImageSharp.original.src}
      />
    </StyledSectionWrapper>
  );
};
export default CreamsScreenInHalf;
