import styled from 'styled-components';
import { breakpoints } from '../../theme/theme';

export const StyledSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 50vw;
  padding: 10rem 2rem;

  @media (max-width: ${`${breakpoints.xl}px`}) {
    flex-direction: column;
    padding: 0;
    row-gap: 3rem;
    padding-bottom: 7rem;
  }
`;

export const StyledTitleWrapper = styled.div`
  max-width: 25%;
  width: 100%;
  display: grid;
  place-items: center;

  @media (max-width: ${`${breakpoints.xl}px`}) {
    max-width: 100%;
    order: 1;
    background-color: ${({ theme }) => theme.colors.orange};
    min-height: 30vw;
    max-height: 20rem;
    padding: 3rem 0;
  }
`;

export const StyledTitle = styled.h2`
  width: 18rem;
  font-size: 4rem;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.orange};
  text-align: center;
  text-transform: uppercase;

  @media (max-width: ${`${breakpoints.xl}px`}) {
    color: ${({ theme }) => theme.colors.white};
    font-size: 3.5rem;
  }
  @media (max-width: ${`${breakpoints.sm}px`}) {
    font-size: 3rem;
  }
`;

export const StyledImagesWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
  column-gap: 4rem;

  @media (max-width: ${`${breakpoints.xl}px`}) {
    order: 2;
    padding: 0 2rem;
    column-gap: 2rem;
  }

  @media (max-width: ${`${breakpoints.sm}px`}) {
    column-gap: 1rem;
  }
`;

export const StyledImageWrapper = styled.div`
  max-width: 45rem;
  width: 100%;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
