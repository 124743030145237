import React from 'react';
import parse from 'html-react-parser';

import {
  StyledBackground,
  StyledContainer,
  StyledWrapper,
  StyledContent,
  StyledTitle,
  StyledParagraph,
  StyledSubtitle,
  StyledColumn,
  StyledLeftColumn,
  StyledImage,
} from './SeductionStyled';

const Seduction = ({ data }) => (
  <StyledBackground
    $url={data.background.localFile.childImageSharp.original.src}
  >
    <StyledImage
      Tag="div"
      fluid={data.background.localFile.childImageSharp.fluid}
      alt={data.title}
    />

    <StyledContainer>
      <StyledWrapper>
        <StyledTitle>{parse(data.title)}</StyledTitle>
        <StyledContent>
          <StyledLeftColumn>
            <StyledColumn>
              <StyledSubtitle>{parse(data.subtitle1)}</StyledSubtitle>
              <StyledParagraph>{parse(data.text1)}</StyledParagraph>
            </StyledColumn>
            <StyledColumn>
              <StyledSubtitle>{parse(data.subtitle2)}</StyledSubtitle>
              <StyledParagraph>{parse(data.text2)}</StyledParagraph>
            </StyledColumn>
          </StyledLeftColumn>
          <StyledColumn>
            <StyledSubtitle>{parse(data.subtitle3)}</StyledSubtitle>
            <StyledParagraph>{parse(data.text3)}</StyledParagraph>
          </StyledColumn>
        </StyledContent>
      </StyledWrapper>
    </StyledContainer>
  </StyledBackground>
);

export default Seduction;
