import styled from 'styled-components';

export const StyledSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
  }
`;

export const StyledTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2rem;
  padding: 3rem;
  width: 100%;
  height: 35vw;
  min-height: 30rem;
  order: 1;
  background-color: ${({ backgroundColor }) => `#${backgroundColor}`};

  ${({ theme }) => theme.mq.xl} {
    order: 2;
    width: 50%;
    height: 50vw;
  }
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  z-index: 9;
  order: 2;

  width: 100%;
  min-height: 100vw;

  ${({ theme }) => theme.mq.xl} {
    max-width: 50%;
    width: 100%;
    min-height: 50vw;
    order: 1;
  }

  .swiper-wrapper {
    padding-bottom: 0 !important;
  }

  .slider-arrow {
    display: none;
  }

  .swiper-pagination {
    bottom: 2rem !important;
  }

  .swiper-pagination-bullet {
    bottom: 2rem !important;
    width: 20px !important;
    height: 20px !important;
    border: 1px solid ${({ theme }) => theme.colors.white};
    background-color: transparent;
    &-active {
      background-color: ${({ theme }) => theme.colors.white} !important;
    }
  }
`;

export const StyledTitle = styled.div`
  h3 {
    font-size: 3.6rem;
    font-weight: bolder;
    line-height: 4.2rem;
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  font-weight: 400;

  h4 {
    text-align: center;
    font-size: 2.6rem;
    line-height: 3.2rem;
  }
`;

export const StyledImg = styled.img`
  width: 100%;
  height: 100vw;
  cursor: pointer;
  object-fit: cover;

  ${({ theme }) => theme.mq.xl} {
    height: 50vw;
  }
`;
