import styled from 'styled-components';

export const StyledHeroBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${({ url }) => url});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-top: 12rem;
  padding-bottom: 5rem;

  ${({ theme }) => theme.mq.lg} {
    padding-top: 16rem;
  }
`;

export const StyledTitleWrapper = styled('div')`
  width: fit-content;
`;

export const StyledTitle = styled('div')`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

export const StyledSubtitle = styled('p')`
  font-size: 3rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 4.5rem;
  }
`;

export const StyledQuote = styled('h3')`
  font-size: 3.5rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 4.5rem;
  }
`;

export const StyledContact = styled('div')`
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.8rem 5rem;
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`;

export const StyledSocial = styled('div')`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3.6rem 5rem;
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`;
