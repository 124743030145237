import React, { useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { isBrowser } from 'react-device-detect';
import { moisturizeAnimation } from 'theme/Animations';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';

import {
  StyledSectionWrapper,
  StyledText,
  StyledTextColumn,
  StyledTitle,
  StyledImg,
  StyledImageWrapper,
} from './LipstickStyled';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const sliderParams = {
  slidesPerView: 1,
  slidesPerGroup: 1,
  centeredSlides: true,
  spaceBetween: 1,
  loop: true,
  pagination: { clickable: true },
};

const Lipstick = ({ data, currentLocale }) => {
  let containerRef = useRef(null);
  let contentRef = useRef(null);
  let imageRef = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      moisturizeAnimation(containerRef, contentRef, imageRef);
    }
  }, []);

  return (
    <StyledSectionWrapper
      ref={el => {
        containerRef = el;
      }}
    >
      <StyledImageWrapper
        ref={el => {
          imageRef = el;
        }}
      >
        <Swiper {...sliderParams}>
          {data.images &&
            data.images.map((image, index) => {
              const alt = image.alternativeText ?? 'slider image';
              return (
                <SwiperSlide key={index}>
                  <StyledImg
                    src={image.localFile.childImageSharp.original.src}
                    alt={alt}
                    onClick={() => {
                      if (image.caption) {
                        const links = image.caption.split('|');
                        window.open(
                          currentLocale === 'en' && links[1] ? links[1] : links[0],
                          '_blank'
                        );
                      }
                    }}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </StyledImageWrapper>
      <StyledTextColumn
        backgroundColor={data.backgroundColor}
        invert={data.invert}
        ref={el => {
          contentRef = el;
        }}
      >
        <StyledTitle>{parse(data.title)}</StyledTitle>
        <StyledText>{parse(data.text)}</StyledText>
      </StyledTextColumn>
    </StyledSectionWrapper>
  );
};
export default Lipstick;
