import styled from 'styled-components';

export const StyledTitle = styled('h2')`
  font-size: 2.4rem;
  line-height: 3.6rem;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
`;

export const StyledTitelWrapper = styled('div')`
  margin-bottom: 6.5rem;
`;

export const StyledSubtitle = styled('h2')`
  font-size: 1.6rem;
  text-align: center;
  line-height: 2.8rem;
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.grey};
`;

export const StyledFormWrapper = styled('div')`
  padding: 10rem 2rem;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.mq.lg} {
    padding: 20rem 5rem;
  }

  ${({ theme }) => theme.mq.md} {
    padding: 10rem 5rem;
  }

  background-color: ${({ backgroundColor }) =>
    `#${backgroundColor}` ?? '#FFFFFF'};
`;

export const StyledFormInnerWrapper = styled('div')`
  position: relative;
  max-width: 144rem;
  width: 100%;
`;

export const StyledForm = styled('form')`
  width: 100%;
  margin: 0;
  overflow: hidden;
  transition: filter 0.5s;

  ${({ theme }) => theme.mq.smm} {
    border-radius: 5px;
  }
`;

export const StyledInputRow = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0rem;

  ${({ theme }) => theme.mq.md} {
    grid-gap: 6rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledSubmitBtn = styled('button')`
  width: 100%;
  padding: 2rem;
  text-align: center;
  border-radius: 4px;
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-weight: 600;
  letter-spacing: 2px;
  background-color: ${({ theme }) => theme.colors.greenLight};
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.3s;
  border: ${({ theme }) => `1px solid ${theme.colors.white}`};
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.greenLight};
  }
`;
