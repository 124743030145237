import React from 'react';
import {
  StyledImage,
  StyledImageWrapper,
  StyledImagesWrapper,
  StyledSectionWrapper,
  StyledTitle,
  StyledTitleWrapper,
} from './HandCreamsStyled';

const HandCreams = ({ data }) => (
  <StyledSectionWrapper>
    <StyledImagesWrapper>
      {data.images.map((image, index) => (
        <StyledImageWrapper key={index}>
          <StyledImage
            src={image.localFile.childImageSharp.original.src}
            alt={image.alternativeText}
          />
        </StyledImageWrapper>
      ))}
    </StyledImagesWrapper>
    <StyledTitleWrapper>
      <StyledTitle>{data.title}</StyledTitle>
    </StyledTitleWrapper>
  </StyledSectionWrapper>
);

export default HandCreams;
