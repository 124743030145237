import React, { useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { careAnimation } from 'theme/Animations';
import { isBrowser } from 'react-device-detect';
import {
  StyledImage,
  StyledImageWrapper,
  StyledImagesWrapper,
  StyledSectionWrapper,
  StyledTitle,
  StyledTitleWrapper,
} from './PerfumesStyled';

const Perfumes = ({ data }) => {
  let container = useRef(null);
  let title = useRef(null);
  let images = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      careAnimation(container, title, images);
    }
  }, []);

  return (
    <StyledSectionWrapper
      ref={el => {
        container = el;
      }}
    >
      <StyledTitleWrapper $backgroundColor={data.backgroundColor}>
        <StyledTitle
          ref={el => {
            title = el;
          }}
        >
          {parse(data.title)}
        </StyledTitle>
      </StyledTitleWrapper>
      <StyledImagesWrapper
        ref={el => {
          images = el;
        }}
      >
        {data.images.map((image, index) => (
          <StyledImageWrapper key={index}>
            <StyledImage
              src={image.localFile.childImageSharp.original.src}
              alt={image.alternativeText}
            />
          </StyledImageWrapper>
        ))}
      </StyledImagesWrapper>
    </StyledSectionWrapper>
  );
};
export default Perfumes;
