import styled, { css } from 'styled-components';

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.smm} {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 50px;
  }
  border: 2px solid;
  border-color: ${({ isError, theme }) =>
    isError ? theme.colors.red : 'transparent'};
`;

export const StyledLabel = styled.label`
  position: absolute;
  bottom: -2.2rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.greenLight};
  font-weight: 600;
  font-size: 1.6rem;
`;

export const StyledBorder = styled.div`
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;
  top: 2px;
  background-color: ${({ theme, isError }) =>
    isError ? theme.colors.red : theme.colors.greenLight};
  transition: 0.3s;
  ${({ hasContent, isError }) =>
    (hasContent || isError) &&
    css`
      width: 100%;
    `}
`;

export const StyledInput = styled.input`
  position: relative;
  border: none;
  font-size: 2.2rem;
  line-height: 2.8rem;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  outline: none;
  padding: 2.1rem;
  &:focus ~ ${StyledBorder} {
    width: 100%;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: #fff !important;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.greenLight};
    font-size: 2.2rem;
    font-weight: 500;
  }
`;

export const StyledTextarea = styled.textarea`
  border: none;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.4rem 2rem;
  width: 100%;
  max-width: 100%;
  outline: none;
  &:focus ~ ${StyledBorder} {
    width: 100%;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: #fff !important;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.greenLight};
    font-size: 2.2rem;
    font-weight: 500;
  }
`;

export const StyledError = styled('span')`
  position: absolute;
  left: 1rem;
  bottom: -3rem;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.red};
`;
