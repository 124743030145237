export const TemplateEnum = {
  DlaczegoBaija: 'TemplateTitleSubtitleTextBackgorund',
  UwodzimyZapachem: 'TemplateUwodzimyZapachem',
  ListaProduktow: 'TemplateSliderRelacyjny',
  Perfumy: 'TemplatePerfumTitleBackgroundColorImages',
  Kremy: 'TemplateHandCreamTitleImages',
  Pomadki: 'TemplateTitleTextImagesGridPropozycji',
  KremyEkranNaPol: 'TemplateTitleTextSliderMakijaz',
  ContactForm: 'TemplateContactForm',
};
